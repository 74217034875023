import { useParams } from 'react-router-dom'
import { Canvas } from '@react-three/fiber'
import { Stats, Sky, PointerLockControls } from '@react-three/drei'
import { Physics } from '@react-three/cannon'
import { PhyBox, PhyPlane } from '../models'
import Player from '../models/Player'
import RemotePlayer from '../models/RemotePlayer'
import useWebRTC from '../../api/useWebRTC'
import defaultPhyBox from '../res/default3DModels/defaultPhyBox.json'
import defaultGround from '../res/default3DModels/defaultPhyGroundPlane.json'

const GameRoomPage = () => {
  const { roomId } = useParams()

  if (typeof roomId !== 'string') {
    return <div>Wrong room</div>
  }

  const { clients, localPlayerStream, peerPlayerElements } = useWebRTC(roomId)
  return (
    <div className="relative">
      <div className="w-screen h-screen z-0">
        <Canvas shadows camera={{ fov: 45 }}>
          <ambientLight intensity={0.2} />
          <Sky sunPosition={[100, 100, 100]} />
          <pointLight castShadow intensity={0.8} position={[100, 100, 100]} />

          <Stats />
          <gridHelper />

          {clients &&
            clients.map((clientId: any) => (
              <RemotePlayer
                key={clientId}
                clientId={clientId}
                ref={peerPlayerElements}
              />
            ))}

          <Physics gravity={[0, -30, 0]}>
            <PhyPlane {...(defaultGround as any)} />
            <Player ref={localPlayerStream} />
            <PhyBox {...(defaultPhyBox as any)} />
          </Physics>
          <PointerLockControls />
        </Canvas>
      </div>
    </div>
  )
}

export default GameRoomPage
