export const ROUTES = {
  GET_TURN_CREDENTIALS: 'get-turn-credentials',
}

export const ACTIONS = {
  JOIN: 'join',
  LEAVE: 'leave',
  SHARE_ROOMS: 'share-rooms',
  ADD_PEER: 'add-peer',
  REMOVE_PEER: 'remove-peer',
  RELAY_SDP: 'relay-sdp',
  RELAY_ICE: 'relay-ice',
  ICE_CANDIDATE: 'ice-candidate',
  SESSION_DESCRIPTION: 'session-description',
}

// https://sinuswood.herokuapp.com/ - ready to user signaling server
const BASE_URL = 'https://sinuswood.herokuapp.com/'
export default BASE_URL

const stunServersUrls = [
  'stun.l.google.com:19302',
  'stun1.l.google.com:19302',
  'stun2.l.google.com:19302',
  'stun3.l.google.com:19302',
  'stun4.l.google.com:19302',
  'stun.ekiga.net',
  'stun.ideasip.com',
  'stun.schlund.de',
  'stun.stunprotocol.org:3478',
  'stun.voiparound.com',
  'stun.voipbuster.com',
  'stun.voipstunt.com',
  'stun.voxgratia.org',
]

export const stunServers = stunServersUrls.map((url) => ({
  url: `stun:${url}`,
}))
