import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import socket from '../../api/socket'
import { ACTIONS } from '../../api/routes'
import Spinner from '../common/Spinner'

const HomePage = () => {
  const navigate = useNavigate()
  const [rooms, updateRooms] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    socket.on(ACTIONS.SHARE_ROOMS, ({ rooms = [] } = {}) => {
      updateRooms(rooms)
      setLoading(false)
    })
    setTimeout(() => {
      setLoading(false)
    }, 7000)
    return () => {
      socket.off(ACTIONS.SHARE_ROOMS)
    }
  }, [])

  return (
    <div className="text-center">
      <div className="bg-gray-300 p-5 inline-block mt-10 rounded">
        <h1 className="text-2xl mb-20">Available Rooms:</h1>

        <ul className="w-96 min-h-20">
          {loading ? (
            <Spinner />
          ) : (
            <>
              {rooms.length > 0 ? (
                rooms.map((roomId) => (
                  <li
                    key={roomId}
                    className="flex justify-between items-center"
                  >
                    <div className="w-60 h-5 overflow-hidden">{roomId}</div>
                    <button
                      className="bg-gray-600 text-white px-2 py-1 rounded ml-4 hover:bg-gray-500"
                      onClick={() => {
                        navigate(`/room/${roomId}`)
                      }}
                    >
                      JOIN ROOM
                    </button>
                  </li>
                ))
              ) : (
                <div>No rooms Available. Create your own room.</div>
              )}
            </>
          )}
        </ul>

        <button
          className="block bg-gray-400 p-1 rounded-sm mt-16 hover:bg-gray-500 hover:text-white"
          onClick={() => {
            navigate(`/room/${v4()}`)
          }}
        >
          Create New Room
        </button>
      </div>
    </div>
  )
}

export default HomePage
