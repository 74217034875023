import { BrowserRouter, Route, Routes } from 'react-router-dom'
import GameRoomPage from './pages/GameRoomPage'
import HomePage from './pages/HomePage'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="room">
          <Route path=":roomId" element={<GameRoomPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
