import { useEffect, useRef, useCallback } from 'react'
import useStateWithCallback from '../hooks/useStateWithCallback'
import socket from './socket'
import { ACTIONS, ROUTES } from './routes'
import api from './axios'
import { IPlayerStream } from 'types/PlayerStream'

interface IHandlePeer {
  peerId: string
  createOffer: boolean
}
interface IRemoteDescr {
  peerId: string
  sessionDescription: RTCSessionDescription
}

export default function useWebRTC(roomId: string) {
  const [clients, updateClients] = useStateWithCallback([])

  const addNewClient = useCallback(
    (newClient) => {
      updateClients((list: string[]) => {
        if (!list.includes(newClient)) {
          return [...list, newClient]
        }
        return list
      })
    },
    [clients, updateClients],
  )

  const handleRemovePeer = useCallback(
    ({ peerId }) => {
      if (peerConnections.current[peerId]) {
        peerConnections.current[peerId].close()
      }
      delete peerConnections.current[peerId]
      delete peerPlayerElements.current[peerId]
      updateClients((list: string[]) => list.filter((c) => c !== peerId))
    },
    [updateClients],
  )

  const peerConnections = useRef<{ [key: string]: RTCPeerConnection }>({})

  const localPlayerStream = useRef<IPlayerStream | null>(null)
  const peerPlayerElements = useRef<{ [key: string]: IPlayerStream }>({})
  const turnServers = useRef<RTCIceServer[] | null>(null)

  useEffect(() => {
    async function handleNewPeer({ peerId, createOffer }: IHandlePeer) {
      try {
        peerConnections.current[peerId] = new RTCPeerConnection({
          iceServers: [
            ...(turnServers.current || [{ urls: 'stun:stun.1und1.de:3478' }]),
          ],
        })
        peerConnections.current[peerId].onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit(ACTIONS.RELAY_ICE, {
              peerId,
              iceCandidate: event.candidate,
            })
          }
        }
        peerConnections.current[peerId].onconnectionstatechange = () => {
          if (peerConnections.current[peerId].connectionState === 'connected') {
            console.log('Succesfully connected with other peer: ', peerId)
          }
        }
        const dataChannel = peerConnections.current[peerId].createDataChannel(
          'player',
          { ordered: true },
        )

        const handleCloseChannel = () => {
          handleRemovePeer({ peerId })
          clearInterval(sendDataTimer)
        }

        const sendDataTimer: any = (func: () => void) => setInterval(func, 50)

        dataChannel.onopen = () => {
          sendDataTimer(() => {
            if (
              localPlayerStream.current &&
              dataChannel &&
              dataChannel.readyState === 'open'
            ) {
              dataChannel.send(JSON.stringify(localPlayerStream.current))
            }
          })
        }
        dataChannel.onerror = () => {
          handleCloseChannel()
        }

        peerConnections.current[peerId].ondatachannel = (event) => {
          addNewClient(peerId)
          const receiveChannel = event.channel
          receiveChannel.onmessage = (event) => {
            peerPlayerElements.current[peerId] = JSON.parse(event.data)
          }
          receiveChannel.onclose = () => {
            handleCloseChannel()
          }
        }

        if (createOffer) {
          const offer = await peerConnections.current[peerId].createOffer()

          await peerConnections.current[peerId].setLocalDescription(offer)
          socket.emit(ACTIONS.RELAY_SDP, {
            peerId,
            sessionDescription: offer,
          })
        }
      } catch (e) {
        console.log(e)
      }
    }
    socket.on(ACTIONS.ADD_PEER, handleNewPeer)

    return () => {
      socket.off(ACTIONS.ADD_PEER)
    }
  }, [])

  useEffect(() => {
    async function setRemoteMedia({
      peerId,
      sessionDescription: remoteDescription,
    }: IRemoteDescr) {
      await peerConnections.current[peerId].setRemoteDescription(
        remoteDescription,
      )

      if (remoteDescription.type === 'offer') {
        const answer = await peerConnections.current[peerId].createAnswer()

        await peerConnections.current[peerId].setLocalDescription(answer)
        socket.emit(ACTIONS.RELAY_SDP, {
          peerId,
          sessionDescription: answer,
        })
      }
    }
    socket.on(ACTIONS.SESSION_DESCRIPTION, setRemoteMedia)

    return () => {
      socket.off(ACTIONS.SESSION_DESCRIPTION)
    }
  }, [])

  useEffect(() => {
    api
      .get(ROUTES.GET_TURN_CREDENTIALS)
      .then((res) => {
        turnServers.current = res.data.token.iceServers
        socket.emit(ACTIONS.JOIN, { room: roomId })
      })
      .catch((err) => {
        console.log('Error on get TURN Server: ', err)
      })

    socket.on(ACTIONS.ICE_CANDIDATE, async ({ peerId, iceCandidate }) => {
      try {
        await peerConnections.current[peerId].addIceCandidate(iceCandidate)
      } catch (e) {
        console.error('Failed to add Ice Candidate: ', e)
      }
    })
    socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer)

    return () => {
      socket.emit(ACTIONS.LEAVE)
      socket.off(ACTIONS.ICE_CANDIDATE)
      socket.off(ACTIONS.REMOVE_PEER)
    }
  }, [roomId])

  return {
    clients,
    localPlayerStream,
    peerPlayerElements,
  }
}
