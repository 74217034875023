import { useFrame } from '@react-three/fiber'
import { forwardRef, useRef } from 'react'
import { Vector3, Euler, Quaternion } from 'three'

const RemotePlayer = forwardRef(({ clientId }: any, ref) => {
  const remotePlayer = useRef<any>(null)
  useFrame(() => {
    // @ts-ignore
    const remoteData = ref.current[clientId]
    if (remoteData) {
      const pos = new Vector3(remoteData.p[0], remoteData.p[1], remoteData.p[2])
      const rotEuler = new Euler(
        remoteData.r[0],
        remoteData.r[1],
        remoteData.r[2],
      )
      const rot = new Quaternion().setFromEuler(rotEuler)
      remotePlayer.current.position.lerp(pos, 0.2)
      remotePlayer.current.quaternion.slerp(rot, 0.2)
    }
  })

  return (
    <mesh ref={remotePlayer} position={[0, 1, 2]} castShadow={true}>
      <boxGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" />
    </mesh>
  )
})
export default RemotePlayer
