import { forwardRef } from 'react'
import { useBox, BoxProps } from '@react-three/cannon'
import { TBoxMesh, TPhyMesh } from 'types/Meshes'

export const Box = forwardRef<TBoxMesh, JSX.IntrinsicElements['mesh']>(
  ({ material, geometry, ...meshProps }, ref) => {
    return (
      <mesh ref={ref} {...meshProps}>
        <boxGeometry {...geometry} />
        {Array.isArray(material) ? (
          material.map((mat) => (
            <meshStandardMaterial key={mat.uuid} {...mat} />
          ))
        ) : (
          <meshStandardMaterial {...material} />
        )}
      </mesh>
    )
  },
)

export const PhyBox = ({
  cannon,
  ...meshProps
}: TPhyMesh<TBoxMesh, BoxProps>) => {
  const [ref] = useBox(() => ({ ...cannon }))

  return (
    <mesh ref={ref}>
      <Box {...meshProps} />
    </mesh>
  )
}
