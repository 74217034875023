import { useState, useCallback, useRef, useEffect } from 'react'

const useStateWithCallback = (initialState: any) => {
  const [state, setState] = useState<any>(initialState)
  const cbRef = useRef<any>(null)

  const updateState = useCallback((newState, cb) => {
    cbRef.current = cb

    setState((prev: string[]) =>
      typeof newState === 'function' ? newState(prev) : newState,
    )
  }, [])

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state)
      cbRef.current = null
    }
  }, [state])

  return [state, updateState]
}

export default useStateWithCallback
