import { forwardRef } from 'react'
import { usePlane, PlaneProps } from '@react-three/cannon'
import { TPlaneMesh, TPhyMesh } from 'types/Meshes'

export const Plane = forwardRef<TPlaneMesh, JSX.IntrinsicElements['mesh']>(
  ({ material, geometry, ...meshProps }, ref) => {
    return (
      <mesh ref={ref} {...meshProps}>
        <planeGeometry {...geometry} />
        {Array.isArray(material) ? (
          material.map((mat) => (
            <meshStandardMaterial key={mat.uuid} {...mat} />
          ))
        ) : (
          <meshStandardMaterial {...material} />
        )}
      </mesh>
    )
  },
)

export const PhyPlane = ({
  cannon,
  ...meshProps
}: TPhyMesh<TPlaneMesh, PlaneProps>) => {
  const [ref] = usePlane(() => ({ ...cannon }))

  return (
    <mesh ref={ref}>
      <Plane {...meshProps} />
    </mesh>
  )
}
