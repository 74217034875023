import { Vector3, MathUtils } from 'three'
import { useEffect, useRef, forwardRef } from 'react'
import { useSphere } from '@react-three/cannon'
import { useThree, useFrame } from '@react-three/fiber'
import usePlayerControls from './usePlayerControl'
import { TTriNumber } from 'types/TriNumbers'

const SPEED = 5
const direction = new Vector3()
const frontVector = new Vector3()
const sideVector = new Vector3()
const rotation = new Vector3()
const speed = new Vector3()

const Player = forwardRef((props: any, playerRef: any) => {
  const axe = useRef<any>()
  const [ref, api]: any = useSphere(() => ({
    mass: 1,
    type: 'Dynamic',
    position: [0, 1, 2],
  }))
  const { forward, backward, left, right, jump } = usePlayerControls()
  const { camera } = useThree()
  const velocity = useRef<TTriNumber>([0, 0, 0])
  useEffect(
    () => api.velocity.subscribe((v: any) => (velocity.current = v)),
    [],
  )
  useFrame((state) => {
    ref.current.getWorldPosition(camera.position)
    frontVector.set(0, 0, Number(backward) - Number(forward))
    sideVector.set(Number(left) - Number(right), 0, 0)
    direction
      .subVectors(frontVector, sideVector)
      .normalize()
      .multiplyScalar(SPEED)
      .applyEuler(camera.rotation)
    speed.fromArray(velocity.current)

    axe.current.children[0].rotation.x = MathUtils.lerp(
      axe.current.children[0].rotation.x,
      Math.sin(speed.length() * state.clock.elapsedTime * 10) / 6,
      0.1,
    )
    axe.current.rotation.copy(camera.rotation)
    axe.current.position
      .copy(camera.position)
      .add(camera.getWorldDirection(rotation).multiplyScalar(1))

    api.velocity.set(direction.x, velocity.current[1], direction.z)
    if (jump && Math.abs(+velocity.current[1].toFixed(2)) < 0.05)
      api.velocity.set(velocity.current[0], 10, velocity.current[2])
  })
  useEffect(() => {
    api.position.subscribe((p: TTriNumber) => {
      playerRef.current = {
        ...playerRef.current,
        p: [+p[0].toFixed(2), +p[1].toFixed(2), +p[2].toFixed(2)],
        r: [
          +camera.rotation.x.toFixed(2),
          +camera.rotation.y.toFixed(2),
          +camera.rotation.z.toFixed(2),
        ],
      }
    })
  }, [])
  return (
    <>
      <mesh ref={ref} />
      <group
        ref={axe}
        onPointerMissed={() => (axe.current.children[0].rotation.x = -0.5)}
      >
        <mesh position={[0.08, -0.15, 0.65]}>
          <boxGeometry args={[0.1, 0.05, 0.1]} />
          <meshStandardMaterial color="#989898" />
        </mesh>
      </group>
    </>
  )
})

export default Player
